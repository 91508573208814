export default function useSideBarMenu() {
  const { $logger } = useNuxtApp();
  const router = useRouter();
  const show = useState<boolean>('sidebar', () => false);
  const active = computed<number>({
    get: () => {
      const name = getPageName(router.currentRoute.value);
      switch (name) {
        case 'index':
          return 0;
        case 'dashboard':
          return 1;
        default:
          $logger.warn('Unknown entry for sidebar:', name);
          return 0;
      }
    },
    set: () => toggleSidebarMenu(),
  });

  const toggleSidebarMenu = (event?: Event): void => {
    event?.preventDefault();
    show.value = !show.value;
  };

  return { active, show, toggleSidebarMenu };
}
